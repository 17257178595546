'use strict';

angular.module('enervexSalesappApp').controller('VentMaterialCtrl', function($scope, VentMaterial, BuildType, $stateParams, Flash, $state, _, Util) {
	$scope.errors = {};
	$scope.ctx = {
		formChanged: false
	}
	$scope.itemCodeDisabled = true
	$scope.codeWarning = Util.setCodeWarning()
	$scope.disableItemCode = function(){
		if ($scope.itemCodeDisabled === true) {
			$scope.itemCodeDisabled = false
		} else {
			$scope.itemCodeDisabled = true
		}
	}
	if ($stateParams.id == "new") {
		$scope.ventMaterial = {}
		fetchDependencies()
		$scope.itemCodeDisabled = false
	} else {
		VentMaterial.get({
			id: $stateParams.id
		}).$promise.then(function(ventMaterial) {
			$scope.ventMaterial = ventMaterial;
			fetchDependencies()
		})
	}
	function fetchDependencies() {
		BuildType.query().$promise.then(function(buildTypes){
			$scope.buildTypes = _.map(buildTypes, function(buildType){
				var existing = _.find($scope.ventMaterial.buildTypes, function(_bt){
					return _bt._id == buildType._id
				})
				if (existing){
					buildType.selected = true
				}
				return buildType
			})
		})
	}
	$scope.save = function(form) {
		$scope.submitted = true;
		if (form.$valid) {
			Flash.clear();
			var payload = _.clone($scope.ventMaterial);
			payload = _.omit(payload, "__v");
			payload.buildTypes = _.filter($scope.buildTypes, function(a){
				return a.selected
			})
			if (!payload._id) {
				VentMaterial.save(payload).$promise.then(function(result) {
					
					$scope.success = true;
					// $scope.ventMaterial = result;
					Flash.create('success', '<strong>Success!</strong> Successfully created ventMaterial.', 0, {}, true);
					$state.go("vent-material", {
						id: result._id
					}, {
						inherit: false,
						reload: true
					})
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			} else {
				VentMaterial.update({
					id: payload._id
				}, payload).$promise.then(function(ventMaterial) {
					
					$scope.success = true;
					// $scope.ventMaterial = ventMaterial;
					Flash.create('success', '<strong>Success!</strong> Successfully updated ventMaterial.', 0, {}, true);
				}).catch(function(err) {
					
					$scope.errors = {};
					$scope.errors.other = 'Problem saving';
					var message = (err.data && err.data.message) || "There was an error saving";
					Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
				})
			}
		} else {
			
			$scope.errors = {};
			$scope.errors.other = 'Problem saving';
			var message = "Please fix validation errors";
			Flash.create('danger', '<strong>Error!</strong> ' + message, 0, {}, true);
		}
	};
});
